.operator-config-page{
    margin: 10px;
    .operatorSelect{
        width: 200px;
    }
    .blockAgentPortal{
        margin: 10px;
        background-color: white;
        padding: 10px;
        border-radius: 5px;
        width: 90vw;
    }
}
