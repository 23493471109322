@import 'partials/variables';
@import 'partials/mixins';
@import 'partials/base';
@import 'partials/forms';
@import 'partials/icons';
@import '~antd/dist/antd.css';

:root {
    --header-height: 80px;
}

.cursorPointer{
    cursor: pointer;
}

.gstTable{
    white-space: pre;
}

.tab-block{
    border-top: 1px solid #d9d9d9;
}
.revenue-details{
    .ant-table-tbody{
        padding: 0;
    }

    .ant-table-tbody > tr > td{
        padding: 0;
    }
}

.custom-padding-tb{
    height: 650px;
    width: 500px;
    .ant-table-tbody{
        padding: 2px;
    }

    .ant-table-tbody > tr > td{
        padding: 2px;
    }

    .ant-table-thead > tr > th{
        padding: 2px;
    }
}

#root-index-html-file{
    width: inherit;
}

body {
    background-color: var(--secondaryColor);
    display: flex;
    padding-top: var(--header-height);
    min-height: 100vh;
    width: 100%;
}

h1,h2,h3,h4,h5{
    color: #000000;
}

.strike-text{
    text-decoration: line-through;
}

.nextLineWordBreak{
    word-wrap: break-word
}

.root-div{
    font-family: Arial, Helvetica, sans-serif!important;
    width: 100%;
    // margin-top: var(--header-height);
}

.fontWeightBold{
    font-weight: bold;
}

.header-normal-text-same-line{
    h4{
        display: flex;
    }
    span{
        display: flex;
    }
    display: flex;
    flex-direction: row;
}

.menu-heading-title{
    // font-family: Times New Roman;
    font-style: normal;
    font-weight: normal;
    font-size: 25px; 
    line-height: 39px;
    color: #FFFFFF;
    padding: 20px;
}

//antd styles

.defalut-input-style,.defalut-input-style:focus,.defalut-input-style:hover,.defalut-picker-style,.defalut-picker-style:hover,.defalut-picker-style:focus{
    background-color: #FFFFFF;
    height: 40px;
    box-sizing: border-box;
    border-radius: 6px;
    min-width: 250px;
}

.defalut-select-style , .defalut-select-style:focus,.defalut-select-style:hover{
    background-color: #FFFFFF;
    height: 40px;
    box-sizing: border-box;
    border-radius: 6px;
    min-width: 250px;
    color: #929292;
    // .last-item-drop-down{
    //     padding: 50px ;
    // }
}

.ant-picker-input > input{
    color: #929292!important;   
}

// .ant-select-item:last-child{
//     padding-bottom: 50px ;
// }


.defalut-multi-select-style{
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 6px;
    min-width: 350px;
    max-width: 350px;
    @media only screen and (max-width: 768px) {
        min-width: 250px;
        max-width: 250px;
    }

}

.ant-btn,.ant-btn:hover,.ant-btn:focus{
    color: #FFFFFF;
    height: 40px;
    background: var(--defaultButtonColor);
    border-radius: 6px; 
    border-color:var(--defaultButtonColor);
    font-weight: bold;
    justify-content: center;
    color : var(--defaultButtonText);
}

.ant-btn-background-ghost,.ant-btn-background-ghost:hover,.ant-btn-background-ghost:focus{
    color: var(--defaultButtonColor)!important;
}

.ant-radio-checked .ant-radio-inner{
    border-color: var(--defaultButtonColor) !important ;
}
  
.ant-radio-checked .ant-radio-inner:after{
    background-color: var(--defaultButtonColor);
}
  
.ant-radio:hover .ant-radio-inner {
    border-color: var(--defaultButtonColor) ;
}

.ant-radio-checked .ant-radio-inner:focus{
    border-color: var(--defaultButtonColor);
}

.ant-checkbox-checked .ant-checkbox-inner ,.ant-checkbox-inner:focus , .ant-checkbox-inner:active,.ant-checkbox-inner:hover{
    background-color: var(--defaultButtonColor)!important;
    border-color: var(--defaultButtonColor)!important;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f5f5f5!important;
    border-color: #d9d9d9 !important;
}
  
.ant-checkbox-checked::after{
    border: 0px solid var(--defaultButtonColor)!important;
}

.ant-form-item-has-error{
    .ant-radio-inner{
        border-color: #ff4d4f!important ;
    }
}
  
.ant-menu-horizontal{
    background-color: var(--headerBgColor)!important;
    color: var(--headerTxtColor)!important;
}

.ant-dropdown {
    background-color: var(--dropDownMenuBgColor)!important;
    color: var(--dropDownMenuTxtColor)!important;
}

.ant-menu-sub {
    background-color: var(--dropDownMenuBgColor)!important;
    color: var(--dropDownMenuTxtColor)!important;
    .ant-menu-item{
       span > a {
            color: var(--dropDownMenuTxtColor)!important;
        }
        
        color: var(--dropDownMenuTxtColor)!important;
    }
    .ant-menu-item-selected,.ant-menu-item:hover , .ant-select-item-option-active , .ant-select-item-option:hover,
    .ant-menu-submenu-open,.ant-menu-submenu-selected,.ant-menu-submenu-active,.ant-menu-submenu:hover{
        span > a {
            background-color: var(--dropDownSelectedMenuBgColor)!important;
            color: var(--dropDownSelectedTxtColor)!important;
        }

        background-color: var(--dropDownSelectedMenuBgColor)!important;
        color: var(--dropDownSelectedTxtColor)!important;
    }
}
.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a{
    color: var(--headerTxtColor);
}
.small-font{
    font-size: 12px;
}

.small-button,.small-button:hover,.small-button:focus,.small-button:active{
    display: flex;
    height:26px;
    font-size: 13px;
    text-align: center;
    align-items: center;
}

.control-margin{
    margin: 2px!important;
    padding: 2px!important;
}

.ant-menu-item-selected,.ant-menu-item:hover , .ant-select-item-option-active , .ant-select-item-option:hover{
    background-color: var(--dropDownSelectedMenuBgColor)!important;
    color: var(--dropDownSelectedTxtColor)!important;
}

.passenger-block{
    margin-top: 5px;
    @media only screen and (max-width: 768px) {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 6px;
        border-bottom: 1px solid #D1D1D1;
    }
}

.displayNone{
    display: none!important;
}


.marginTop{
    margin-top: 10px!important;
}

.marginBottom{
    margin-bottom: 10px!important;
}

.marginLeft{
    margin-left: 10px!important;
} 
.marginRight{
    margin-right: 10px!important;
}

@page  
{ 
    size: auto;   /* auto is the initial value */ 
    margin: 0mm 10mm 20mm 10mm; 
    margin-top: 20px;
}

.transform90Degrees{
    transform: rotate(90deg);
}
.transparentButton,.transparentButton:hover,.transparentButton:focus{
    color: #000000;
    height: 40px;
    background: transparent;
    border-radius: 6px; 
    border-color: transparent;
    font-weight: bold;
    justify-content: center;
}


.cancel-button,.cancel-button:hover,.cancel-button:focus{
    background: #929292;
    border-color: #929292;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

.flexWrapInheriant{
    flex-wrap: inherit;
}

.justifyContent{
    justify-content: center;
}

.alignMiddle {
    align-content: center;
}

.alignItems{
    align-items: center;
}

.empty-header-table{
    .ant-table{
        .ant-table-thead > tr > th {
          display: none;
        }
    }
}

.ant-table{
    .ant-table-thead > tr > th {
        background-color: #FFFFFF!important;
        color: #000000!important;
        font-weight: bold;
    }
}

.ant-input-status-error{
    border-color: #ff4d4f;
}

.ant-tabs-tab-active > .ant-tabs-tab-btn , 
.ant-tabs-tab-btn :hover , 
.ant-tabs-tab-btn :focus{
    color: var(--defaultButtonColor)!important;
    font-weight: bold!important;
}

.ant-tabs-tab-active > .ant-tabs-tab-btn{
    background: #FFFFFF!important;   
}

.ant-tabs-ink-bar{
    background : var(--defaultButtonColor)!important;
}

.ant-tabs-tab:hover{
    color: var(--defaultButtonText)!important;
}

.ant-tabs-tab-btn{
    font-weight: bold!important;
    min-height: 40px;
    padding: 6px 6px;
}

.ant-tabs-tab-btn:focus{
    color: #000000;
}

.ant-tabs-top > div > .ant-tabs-nav::before{
    border-bottom:none;
}

.ant-tabs-top > div > .ant-tabs-nav{
    margin: 0px;
}

.ant-tabs-tab{
    padding: 0px;
}

.ant-tabs-mobile{
    margin-right: 0px!important;
}

.remove-timer-icon{
    .ant-picker-suffix{
        display: none;
    }
}

.remove-clear-icon{
    .ant-picker-clear{
        display: none;
    }
}

.time-picker-to-not-display-footer {
    .ant-picker-panel-container{
      .ant-picker-panel{
        .ant-picker-footer {
          display: none;
         }
      }
    }
  }


  .ant-slider-handle , .ant-slider-handle:hover , .ant-slider-handle:focus , .ant-slider-handle:active{
    border: solid 2px var(--defaultButtonColor);
  }

  .ant-slider-track , .ant-slider-track:hover ,  .ant-slider-track:focus ,  .ant-slider-track:active{
    background-color: var(--defaultButtonColor);
  }

  .ant-slider-dot-active , .ant-slider-dot-active:hover , .ant-slider-dot-active:focus , .ant-slider-dot-active:active{
    border-color: var(--defaultButtonColor); 
  }

  .ant-slider:hover{
    border-color: var(--defaultButtonColor); 
  }

  .ant-slider:hover .ant-slider-track {
    background-color: var(--defaultButtonColor); 
  }
  
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: var(--defaultButtonColor); 
  }

//   .ant-table.ant-table-bordered > .ant-table-title {
//     border: 1px solid black;
//   }
//   .ant-table.ant-table-bordered > .ant-table-container {
//     border-left: 1px solid black;
//   }
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > th,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > th,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > td {
//     border-right: 1px solid black;
//   }
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr:not(:last-child) > th {
//     border-bottom: 1px solid black;
//   }
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > thead > tr > .ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > .ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
//     border-right: 1px solid black;
//   }
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-summary > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
//     border-right: 1px solid black;
//   }
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table,
//   .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
//     border-top: 1px solid black;
//   }
//   .ant-table.ant-table-bordered > .ant-table-footer {
//     border: 1px solid black;
//   }


//private 
.trip-sheet-bordered-table{
    .ant-table-thead > tr > th {
        border-bottom: 1px solid black;
        border-top: 1px solid black;
        border-left: 1px solid black;
    }
    .ant-table-thead > tr > th:last-child {
        border-right: 1px solid black;
    }
    .ant-table-tbody > tr > td {
        border-bottom: 1px solid black;
        border-left: 1px solid black;
    }
    .ant-table-tbody > tr > td:last-child {
        border-right: 1px solid black;
    }
    .ant-table-summary > tr > td{
        border-bottom: 1px solid black;
        border-left: 1px solid black;
    }

}

.bordered-table{
    .ant-table-thead > tr > th {
        border-bottom: 2px solid #F4F6F9;
        border-top: 0px solid black;
        border-left: 0px solid black;
    }
    .ant-table-thead > tr > th:last-child {
        border-right: 0px solid black;
    }
    .ant-table-thead .ant-table-cell {
        background-color: #E7E9EE !important;
    }
    .ant-table-thead .ant-table-tbody .ant-table-summary {
        border-radius: 10px;
    }
    .ant-table-tbody > tr > td {
        border-bottom: 2px solid #F4F6F9;
        border-left: 0px solid black;
    }
    .ant-table-tbody > tr > td:last-child {
        border-right: 0px solid black;
    }
    .ant-table-summary > tr > td {
        border-bottom: 2px solid #F4F6F9;
        border-left: 0px solid black;
        border-right: 0px solid black !important;
    }
    .ant-table-summary > tr > td:last-child {
        border-right: 1px solid black;
    }

}

@media print {
    table {
        border-collapse: collapse;
        th:first-child{
            // border-top:1.5px solid black !important;
            // border-left:1.5px solid black !important;

        }
        th:last-child{
            // border-top:1px solid black !important;
            // border-right:1px solid black !important;
        }
        thead {
            display: table-row-group;
        }
    }
    .tripSheetPhoneNumber{
        color: #000000!important;
    }
   
}

.link-btn , .link-btn:hover , .link-btn:active , .link-btn:focus {
    background-color: transparent;
    border-radius: 0px;
    border-width: 0px 0px 2px 0px;
    border-color: var(--defaultButtonColor);
    color: var(--defaultButtonColor);
}


.bordered-btn , .bordered-btn:hover , .bordered-btn:active , .bordered-btn:focus {
    background-color: transparent;
    border-width: 2px 2px 2px 2px;
    border-color: var(--defaultButtonColor);
    color: var(--defaultButtonColor);
}


.exit-btn ,  .exit-btn:hover , .exit-btn:active , .exit-btn:focus{
    background-color: #000000!important ;
    border-color: #000000!important;
    color: #FFFFFF!important;
}

.ticket-search-form-block{
    max-width: 100vw;
}

.ticket-search-select .ant-select .ant-select-selector {
    border-radius:5px;
    background-color: #E7DBFF;
    text-align: center;
    height: 40px;
}

.ticket-search-select .ant-select-selection-item {
    margin-top: 4px;
}


.numberCircle {
    display: inline-block;
    line-height: 0px;
    border-radius: 50%;
    border: 1px solid;
    font-size: 6px;
    background-color: #000000;
}

.numberCircle span {
    display: inline-block;
    padding-top: 50%;
    padding-bottom: 50%;
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
    color: #FFFFFF;
}

.tripSheetSplFontSize{
    font-size: 18px!important;
}

.tripSheetBold{
    table {
        tbody {
            tr {
                td {
                    font-weight:bold!important;
                }
            }
        }
    }
}
// antd inputnumber increment or decrement by 1
.ant-input-number-handler-wrap{
    display: none;
}

.blocked-row {
    background-color: #FF8488!important;
}

.blocked-row:hover td {
    background-color: #FF8488 !important;
  }

.ticket-search-table-row {
    cursor: pointer;
}

.cancelledRow , .cancelledRow:hover td{
    background-color: #FF8488!important;
}

.app-table{
    .ant-table-thead .ant-table-cell {
        background-color: #E7E9EE !important;
    }
    .ant-table-thead .ant-table-tbody .ant-table-summary {
        border-radius: 10px;
    }
    .ant-table-thead > tr > th {
        border-bottom: 2px solid #F4F6F9;
        border-top: 0px solid black;
        border-left: 0px solid black;
    }
    .ant-table-thead > tr > th:last-child {
        border-right: 0px solid black;
    }

    .ant-table-tbody > tr > td {
        border-bottom: 2px solid #F4F6F9;
        border-left: 0px solid black;
    }
    .ant-table-tbody > tr > td:last-child {
        border-right: 0px solid black;
    }
    .ant-table-summary > tr > td {
        border-bottom: 2px solid #F4F6F9;
        border-left: 0px solid black;
        border-right: 0px solid black !important;
    }
}

.default-checkbo-btn{
    color: var(--defaultButtonColor);
}

.font-size-12{
    font-size: 12px;
}

.font-size-16{
    font-size: 16px;
}   

.font-weight-bold{
    font-weight: bold;
}

.margin-left-10{
    margin-left: 10px;
}

.margin-bottom-10{
    margin-bottom: 10px;
}

.margin-top-10{
    margin-top: 10px;
}


